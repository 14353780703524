import React from 'react';
import { Link } from 'react-router-dom';
import './Section1.css';

const Section1 = () => {
  const scrollToSection = () => {
    const section2 = document.getElementById('section2');
    if (section2) {
      section2.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <>
      <div className="section" id="home">
        <div className="text-section">
          <h1><span>HARAS </span><span>DE LA PERELLINA</span></h1>
          <p>Adresse: 330 Chemin du Haut Bois, 14340 Manerbe, France</p>
          <p>Téléphone : 07 54 23 57 89 <br /> 06 78 31 33 71</p>
          <button onClick={scrollToSection}>Découvrir le Haras</button>
        </div>
        <div className="image-section">
          <img src={`${process.env.PUBLIC_URL}/1.jpg`} alt="Image du Haras de la Perllina" />
        </div>
      </div>

      <div className="banner">
        <div className="banner-container">
          <div className="banner-section">
            <h2>Découvrir l'écurie active</h2>
            <ul>
              <li><Link to="/ecurie-active#concept">Le concept de l'écurie active</Link></li>
              <li><Link to="/ecurie-active#interets">Les points d'intérêts</Link></li>
              <li><Link to="/ecurie-active#hebergement">L'hébergement en groupe</Link></li>
            </ul>
          </div>
          <div className="banner-section">
            <h2>Découvrir la pension pré</h2>
            <ul>
              <li><Link to="/pension-pre#pres">Les prés</Link></li>
              <li><Link to="/pension-pre#vie-en-troupeau">La vie en troupeau</Link></li>
              <li><Link to="/pension-pre#balades">Chemins de balade</Link></li>
            </ul>
          </div>
          <div className="banner-section">
            <h2>Découvrir le haras</h2>
            <ul>
              <li><Link to="/installations#installations">Installations</Link></li>
              <li><Link to="/#team">Notre équipe</Link></li> {/* Lien mis à jour */}
            </ul>
          </div>
        </div>
      </div>

      <div id="section2">
        {/* Contenu de la section 2 */}
      </div>
    </>
  );
};

export default Section1;
