import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import { useLocation, useNavigate } from 'react-router-dom';
import './Section2.css';

const Section2 = () => {
  const [currentIndex1, setCurrentIndex1] = useState(0);
  const [currentIndex2, setCurrentIndex2] = useState(0);
  const [currentIndex3, setCurrentIndex3] = useState(0);
  const location = useLocation();
  const navigate = useNavigate();

  const handlePrev = (index, setIndex, length) => {
    setIndex((index - 1 + length) % length);
  };

  const handleNext = (index, setIndex, length) => {
    setIndex((index + 1) % length);
  };

  useEffect(() => {
    if (location.hash) {
      const element = document.getElementById(location.hash.substring(1));
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [location]);

  const sectionStyle = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    padding: '20px',
    boxSizing: 'border-box',
    height: '100%',
    width: '100%',
  };

  const textStyle = {
    flex: '1',
    marginBottom: '20px',
  };

  const carouselStyle = {
    position: 'relative',
    width: '100%',
    height: '300px',
    overflow: 'hidden',
    marginBottom: '20px',
  };

  const imgStyle = {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  };

  const arrowStyle = {
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    fontSize: '16px',
    color: 'gray',
    cursor: 'pointer',
    zIndex: 1,
    userSelect: 'none',
    background: 'transparent',
    border: 'none',
  };

  const leftArrowStyle = {
    left: '10px',
  };

  const rightArrowStyle = {
    right: '10px',
  };

  const carouselItemStyle = {
    height: '300px',
  };

  return (
    <>
      <meta name="description" content="Écurie active Lisieux, pension écurie active Lisieux, Haras de la Perellina, Emile Menasria, Anne Claire Henon" />
      <meta name="keywords" content="Écurie active Lisieux, pension écurie active Lisieux, Haras de la Perellina, Emile Menasria, Anne Claire Henon" />
      <div className="section-3 container-fluid">
        <div className="row">
          <div className="col-md-4 col-sm-12 mb-3">
            <div className="container equal-height-container" id="installations" style={sectionStyle}>
              <h1><span>Nos </span> installations</h1>
              <ul style={textStyle}>
                <li>Dans un petit haras privé / 5 min de Lisieux / 15 km de Pont l'évêque</li>
                <li>Une grande carrière 2400m2 (60x40) en sable de Fontainebleau (drainée, éclairée, arrosée, hersée)</li>
                <li>Une petite carrière 300m2 (15x20) en sable de Fontainebleau éclairée pour longe et travail à pied</li>
                <li>Un parc d'obstacles avec soubassements</li>
                <li>Un club-house avec machine à café</li>
                <li>Des sanitaires</li>
                <li>Une douche extérieure</li>
                <li>Un espace sellerie</li>
                <li>Plusieurs salles de soins extérieures et intérieures</li>
                <li>Plusieurs hectares de pré avec abris</li>
                <li>Écurie active</li>
              </ul>
              <div className="carousel" id="carouselExampleIndicators1" style={carouselStyle}>
                <div className="carousel-inner">
                  <div className={`carousel-item ${currentIndex1 === 0 ? 'active' : ''}`} style={carouselItemStyle}>
                    <img src="/ins (8).jfif" alt="Installation - Image 1" className="img-fluid" style={imgStyle} />
                  </div>
                  <div className={`carousel-item ${currentIndex1 === 1 ? 'active' : ''}`} style={carouselItemStyle}>
                    <img src="/ins (10).jfif" alt="Installation - Image 2" className="img-fluid" style={imgStyle} />
                  </div>
                  <div className={`carousel-item ${currentIndex1 === 2 ? 'active' : ''}`} style={carouselItemStyle}>
                    <img src="/ins (14).jpg" alt="Installation - Image 3" className="img-fluid" style={imgStyle} />
                  </div>
                  <div className={`carousel-item ${currentIndex1 === 3 ? 'active' : ''}`} style={carouselItemStyle}>
                    <img src="/ins (15).jpg" alt="Installation - Image 4" className="img-fluid" style={imgStyle} />
                  </div>
                  <div className={`carousel-item ${currentIndex1 === 4 ? 'active' : ''}`} style={carouselItemStyle}>
                    <img src="/ins(23).jpg" alt="Installation - Image 5" className="img-fluid" style={imgStyle} />
                  </div>
                </div>
                <span className="carousel-control-prev" onClick={() => handlePrev(currentIndex1, setCurrentIndex1, 5)} style={{ ...arrowStyle, ...leftArrowStyle }}>◀</span>
                <span className="carousel-control-next" onClick={() => handleNext(currentIndex1, setCurrentIndex1, 5)} style={{ ...arrowStyle, ...rightArrowStyle }}>▶</span>
              </div>
              <button className="btn btn-primary" onClick={() => navigate('/installations')}>En savoir plus</button>
            </div>
          </div>
          <div className="col-md-4 col-sm-12 mb-3">
            <div className="container equal-height-container" id="ecurie-active" style={sectionStyle}>
              <h1 id="ecurie-active-title"><span>Pension </span> écurie active</h1>
              <ul style={textStyle}>
                <li>Tout le confort d'une vie au pré + : </li>
                <li>Distributeur automatique de floconnés pour une alimentation en continue </li>
                <li>Ratelier foin XL 12 places avec auvent </li>
                <li>Dortoir paillé de 200m² </li>
                <li>Terrain de 2000 m² de sol stabilisé </li>
                <li>Abreuvoir automatique niveau constant </li>
                <li>2 zones de roulade sablées ( 1 extérieure et 1 couverte) pour se faire plaisir et s'amuser</li>
                <li>Bar à minéraux et vitamines</li>
                <li>Sas d'entrée et de sortie</li>
                <li>Butte d'observation</li>
                <li>Plusieurs prairies en pâturage tournant </li>
                <li>Paddock d'intégration pour faire connaissance avec les nouveaux en toute sécurité</li>
                <li>Surveillance quotidienne</li>
                <li>Accès à l'ensemble des installations</li>
                <li>Une place pour vos affaires dans la sellerie</li>
                <li>Un cours collectif par semaine</li>
                <li><span className="tarifs">Tarifs: 320 € TTC/mois</span></li>
              </ul>
              <div className="carousel" id="carouselExampleIndicators2" style={carouselStyle}>
                <div className="carousel-inner">
                  <div className={`carousel-item ${currentIndex2 === 0 ? 'active' : ''}`} style={carouselItemStyle}>
                    <img src="/s (2).jpg" alt="Écurie active - Image 1" className="img-fluid" style={imgStyle} />
                  </div>
                  <div className={`carousel-item ${currentIndex2 === 1 ? 'active' : ''}`} style={carouselItemStyle}>
                    <img src="/dortoir.jpg" alt="Écurie active - Image 2" className="img-fluid" style={imgStyle} />
                  </div>
                  <div className={`carousel-item ${currentIndex2 === 2 ? 'active' : ''}`} style={carouselItemStyle}>
                    <img src="/roulade.jfif" alt="Écurie active - Image 3" className="img-fluid" style={imgStyle} />
                  </div>
                  <div className={`carousel-item ${currentIndex2 === 3 ? 'active' : ''}`} style={carouselItemStyle}>
                    <img src="/dac.jpg" alt="Écurie active - Image 4" className="img-fluid" style={imgStyle} />
                  </div>
                  <div className={`carousel-item ${currentIndex2 === 4 ? 'active' : ''}`} style={carouselItemStyle}>
                    <img src="/s (4).jpg" alt="Écurie active - Image 5" className="img-fluid" style={imgStyle} />
                  </div>
                </div>
                <span className="carousel-control-prev" onClick={() => handlePrev(currentIndex2, setCurrentIndex2, 5)} style={{ ...arrowStyle, ...leftArrowStyle }}>◀</span>
                <span className="carousel-control-next" onClick={() => handleNext(currentIndex2, setCurrentIndex2, 5)} style={{ ...arrowStyle, ...rightArrowStyle }}>▶</span>
              </div>
              <button className="btn btn-primary" onClick={() => navigate('/ecurie-active')}>En savoir plus</button>
            </div>
          </div>
          <div className="col-md-4 col-sm-12 mb-3">
            <div className="container equal-height-container" id="pension-pre" style={sectionStyle}>
              <h1 id="pension-pre-title"><span>Pension</span> pré</h1>
              <ul style={textStyle}>
                <li>Hébergement au pré en troupeau de petits groupes</li>
                <li>Abris en dur et abris naturels</li>
                <li>Eau à volonté</li>
                <li>Distribution de foin à volonté toute l'année</li>
                <li>Pierres à sel</li>
                <li>Surveillance quotidienne</li>
                <li>Accès à l'ensemble des installations</li>
                <li>Une place pour vos affaires dans la sellerie</li>
                <li>Un cours collectif par semaine</li>
                <li><span className="tarifs">Tarifs: 220 € TTC/mois</span></li>
              </ul>
              <div className="carousel" id="carouselExampleIndicators3" style={carouselStyle}>
                <div className="carousel-inner">
                  <div className={`carousel-item ${currentIndex3 === 0 ? 'active' : ''}`} style={carouselItemStyle}>
                    <img src="/pré55.jpg" alt="Pension pré - Image 1" className="img-fluid" style={imgStyle} />
                  </div>
                  <div className={`carousel-item ${currentIndex3 === 1 ? 'active' : ''}`} style={carouselItemStyle}>
                    <img src="/pré11.jpg" alt="Pension pré - Image 2" className="img-fluid" style={imgStyle} />
                  </div>
                  <div className={`carousel-item ${currentIndex3 === 2 ? 'active' : ''}`} style={carouselItemStyle}>
                    <img src="/ins (9).jfif" alt="Pension pré - Image 3" className="img-fluid" style={imgStyle} />
                  </div>
                  <div className={`carousel-item ${currentIndex3 === 3 ? 'active' : ''}`} style={carouselItemStyle}>
                    <img src="/ins (13).jfif" alt="Pension pré - Image 4" className="img-fluid" style={imgStyle} />
                  </div>
                  <div className={`carousel-item ${currentIndex3 === 4 ? 'active' : ''}`} style={carouselItemStyle}>
                    <img src="/g6.jfif" alt="Pension pré - Image 5" className="img-fluid" style={imgStyle} />
                  </div>
                </div>
                <span className="carousel-control-prev" onClick={() => handlePrev(currentIndex3, setCurrentIndex3, 5)} style={{ ...arrowStyle, ...leftArrowStyle }}>◀</span>
                <span className="carousel-control-next" onClick={() => handleNext(currentIndex3, setCurrentIndex3, 5)} style={{ ...arrowStyle, ...rightArrowStyle }}>▶</span>
              </div>
              <button className="btn btn-primary" onClick={() => navigate('/pension-pre')}>En savoir plus</button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Section2;
