import React, { useEffect } from 'react';
import './PensionPrePage.css'; // Assurez-vous de créer ce fichier CSS

const features = [
  {
    id: 'feature1',
    title: 'Hébergement au pré en troupeau petits groupes',
    text: `Notre hébergement au pré permet aux chevaux de vivre en groupe dans un environnement naturel et sécurisé. Ils bénéficient de vastes espaces pour se déplacer librement et interagir socialement.`,
    imgSrc: '/pr5.jpg'
  },
  {
    id: 'feature2',
    title: 'Abris en dur et abris naturels',
    text: `Nous offrons des abris en dur et des abris naturels pour protéger les chevaux des intempéries et du soleil, leur assurant ainsi confort et bien-être tout au long de l'année.`,
    imgSrc: '/dur.jfif'
  },
  {
    id: 'feature3',
    title: 'Eau à volonté',
    text: `L'eau est indispensable pour la santé des chevaux. Nous veillons à ce qu'ils disposent en permanence d'eau fraîche et propre, accessible à tout moment.`,
    imgSrc: '/water.jfif'
  },
  {
    id: 'feature4',
    title: 'Distribution de foin à volonté toute l\'année',
    text: `Nos chevaux ont accès à du foin de qualité, distribué à volonté tout au long de l'année, garantissant une alimentation équilibrée et adaptée à leurs besoins.`,
    imgSrc: '/foin.jpg'
  },
  {
    id: 'feature5',
    title: 'Pierres à sel',
    text: `Des pierres à sel sont mises à disposition des chevaux pour qu'ils puissent réguler leur apport en minéraux essentiels, favorisant ainsi leur santé et leur bien-être.`,
    imgSrc: '/pierreasel.jfif'
  },
  {
    id: 'feature6',
    title: 'Surveillance quotidienne',
    text: `Notre résidence est située directement sur le Haras, ce qui nous permet d'assurer une surveillance quotidienne des chevaux. Nous veillons attentivement à leur sécurité, leur santé et leur confort. Cette vigilance constante nous permet d'intervenir rapidement en cas de besoin, garantissant ainsi un environnement serein et sécurisé.`,
    imgSrc: '/m.jpg'
  },
];

const PensionPrePage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="pension-pre-page">
      <div className="header-image">
        <img src="/ins (9).jfif" alt="Pension Pré" />
        <div className="header-text">
          <h1>Pension Pré</h1>
        </div>
      </div>
      <div className="description">
        <p>Notre pension pré offre un cadre de vie naturel et sain pour les chevaux, favorisant leur bien-être et leur épanouissement. Découvrez nos installations et les avantages de notre pension pré.</p>
      </div>
      {features.map((feature, index) => (
        <div id={feature.id} className={`section ${index % 2 === 0 ? 'text-left' : 'text-right'}`} key={index}>
          <div className="text">
            <h2>{feature.title}</h2>
            <p>{feature.text}</p>
          </div>
          <div className="image">
            <img src={feature.imgSrc} alt={feature.title} />
          </div>
        </div>
      ))}
      <div className="section text-left" id="pricing">
        <div className="text">
          <h2><span className="dark-blue">Tarif</span><span className="light-blue"> pension pré </span></h2>
          <ul>
            <li>Hébergement au pré en troupeau petits groupes</li>
            <li>Abris en dur et abris naturels</li>
            <li>Eau à volonté</li>
            <li>Distribution de foin à volonté toute l'année</li>
            <li>Pierres à sel</li>
            <li>Surveillance quotidienne</li>
            <li>Accès à l'ensemble des installations</li>
            <li>Une place pour vos affaires dans la sellerie</li>
            <li>Un cours collectif par semaine</li>
            <li><span className="tarifs">Tarifs: 220 € TTC/mois</span></li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default PensionPrePage;
