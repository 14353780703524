import React from 'react';
import './Section5.css'; // Assurez-vous que ce fichier CSS est bien lié

const Section5 = () => {
    const images = [
        { src: 'g1.jfif', alt: 'écurie active Lisieux', title: 'écurie active Lisieux' },
        { src: 'g2.jpg', alt: 'écurie active Manerbe', title: 'écurie active Manerbe' },
        { src: 'g3.jpg', alt: 'écurie active Haras de la Perellina', title: 'écurie active Haras de la Perellina' },
        { src: 'g4.jpg', alt: 'pension pré Lisieux', title: 'pension pré Lisieux' },
        { src: 'g5.jfif', alt: 'écurie active Emile Menasria', title: 'écurie active Emile Menasria' },
        { src: 'g6.jfif', alt: 'écurie active Anne Claire Henon', title: 'écurie active Anne Claire Henon' },
        { src: 'ins (1).jpg', alt: 'écurie active Lisieux', title: 'écurie active Lisieux' },
        { src: 'ins (2).jpg', alt: 'écurie active Manerbe', title: 'écurie active Manerbe' },
        { src: 'ins (3).jpg', alt: 'écurie active Haras de la Perellina', title: 'écurie active Haras de la Perellina' },
        { src: 'ins (4).jpg', alt: 'pension pré Lisieux', title: 'pension pré Lisieux' },
        { src: 'ins (5).jpg', alt: 'écurie active Emile Menasria', title: 'écurie active Emile Menasria' },
        { src: 'active115.jpg', alt: 'écurie active Anne Claire Henon', title: 'écurie active Anne Claire Henon' },
        { src: 'ins (9).jfif', alt: 'écurie active Lisieux', title: 'écurie active Lisieux' },
        { src: 'ins (8).jfif', alt: 'écurie active Manerbe', title: 'écurie active Manerbe' },
        { src: 'ins (10).jfif', alt: 'écurie active Haras de la Perellina', title: 'écurie active Haras de la Perellina' },
        { src: 'ins (11).jfif', alt: 'pension pré Lisieux', title: 'pension pré Lisieux' },
        { src: 'ins (12).jpg', alt: 'écurie active Emile Menasria', title: 'écurie active Emile Menasria' },
        { src: 'ins (13).jfif', alt: 'écurie active Anne Claire Henon', title: 'écurie active Anne Claire Henon' },
        { src: 'ins (14).jpg', alt: 'écurie active Lisieux', title: 'écurie active Lisieux' },
        { src: 'ins (15).jpg', alt: 'écurie active Manerbe', title: 'écurie active Manerbe' },
        { src: '44.jpg', alt: 'écurie active Haras de la Perellina', title: 'écurie active Haras de la Perellina' },
        { src: 'active3333.jpg', alt: 'pension pré Lisieux', title: 'pension pré Lisieux' },
        { src: 'instal22.jpg', alt: 'écurie active Emile Menasria', title: 'écurie active Emile Menasria' },
        { src: 'pré55.jpg', alt: 'écurie active Anne Claire Henon', title: 'écurie active Anne Claire Henon' },
        { src: 'pré11.jpg', alt: 'écurie active Lisieux', title: 'écurie active Lisieux' },
        { src: 's (1).jpg', alt: 'écurie active Manerbe', title: 'écurie active Manerbe' },
        { src: 's (2).jpg', alt: 'écurie active Haras de la Perellina', title: 'écurie active Haras de la Perellina' },
        { src: 's (3).jpg', alt: 'pension pré Lisieux', title: 'pension pré Lisieux' },
        { src: 's (4).jpg', alt: 'écurie active Emile Menasria', title: 'écurie active Emile Menasria' },
        { src: 's (5).jpg', alt: 'écurie active Anne Claire Henon', title: 'écurie active Anne Claire Henon' },
        { src: 's (6).jpg', alt: 'écurie active Lisieux', title: 'écurie active Lisieux' },
        { src: 's (7).jpg', alt: 'écurie active Manerbe', title: 'écurie active Manerbe' },
        { src: 's (8).jfif', alt: 'écurie active Haras de la Perellina', title: 'écurie active Haras de la Perellina' },
        { src: 's (9).jfif', alt: 'pension pré Lisieux', title: 'pension pré Lisieux' },
        { src: 's (10).jfif', alt: 'écurie active Emile Menasria', title: 'écurie active Emile Menasria' },
        { src: 's (11).jfif', alt: 'écurie active Anne Claire Henon', title: 'écurie active Anne Claire Henon' },
        { src: 's (12).jfif', alt: 'écurie active Lisieux', title: 'écurie active Lisieux' },
        { src: 's (13).jfif', alt: 'écurie active Manerbe', title: 'écurie active Manerbe' },
        { src: 's (14).jfif', alt: 'écurie active Haras de la Perellina', title: 'écurie active Haras de la Perellina' },
        { src: 's (15).jfif', alt: 'pension pré Lisieux', title: 'pension pré Lisieux' },
        { src: 's (16).jfif', alt: 'écurie active Emile Menasria', title: 'écurie active Emile Menasria' },
        { src: 's (17).jpg', alt: 'écurie active Anne Claire Henon', title: 'écurie active Anne Claire Henon' }
    ];

    return (
        <div className="section-5 container-fluid">
            <h2 className="gallery-title">Galerie <span className="blue-text">Photos</span></h2>
            <div className="gallery">
                {images.map((image, index) => (
                    <div key={index} className="gallery-item">
                        <img src={image.src} alt={image.alt} title={image.title} />
                    </div>
                ))}
            </div>
        </div>
    );
}

export default Section5;
