import React, { useEffect } from 'react';
import './InstallationsPage.css';

const features = [

  {
    id: 'feature2',
    title: 'Grande carrière',
    text: `Notre grande carrière de 2400 m² (60x40) en sable de Fontainebleau est un véritable joyau. Elle est parfaitement drainée, éclairée, arrosée et hersée pour garantir des conditions optimales toute l'année. Avec une exposition idéale et une vue panoramique exceptionnelle, elle offre un cadre magnifique pour les séances d'entraînement.`,
    imgSrc: '/instal22.jpg'
  },
  // Ajoutez d'autres fonctionnalités si nécessaire
];

const sections = [
  {
    id: 'Petite Carrière',
    title: 'Petite carrière',
    text: 'Nous disposons également d\'une petite carrière de 300 m² (15x20), également en sable de Fontainebleau et éclairée. Cet espace est parfait pour le travail à pied et la longe, offrant un environnement contrôlé et sécurisé pour des exercices spécifiques.',
    imgSrc: '/ins (10).jfif'
  },
  {
    id: 'Parc d\'obstacles',
    title: 'Parc d\'obstacles',
    text: 'Pour les amateurs de saut d\'obstacles, notre parc est équipé de soubassements variés. Il permet de diversifier les entraînements et de préparer vos chevaux aux compétitions dans les meilleures conditions.',
    imgSrc: '/ra.jpg'
  },
  {
    id: 'Club-House et Sanitaires',
    title: 'Club-House et sanitaires',
    text: 'Notre club-house, équipé d\'une machine à café, est un lieu convivial pour se détendre après les séances d\'entraînement. Vous y trouverez également des sanitaires modernes, assurant un confort optimal pour les cavaliers et visiteurs.',
    imgSrc: '/club.png'
  },
  {
    id: 'Douche Extérieure',
    title: 'Douche extérieure',
    text: 'Une douche extérieure est à votre disposition pour rafraîchir et nettoyer vos chevaux après leurs efforts. Pratique et bien située, elle facilite l\'entretien quotidien.',
    imgSrc: '/ins(23).jpg'
  },
  {
    id: 'Salles de soins',
    title: 'Salles de soins',
    text: 'Notre haras dispose de plusieurs salles de préparation, situées aussi bien en extérieur qu\'en intérieur. Ces installations sont conçues pour être des lieux de passion, de sport et de pratique de l\'équitation. Elles sont idéales pour préparer vos chevaux, les soigner et les embellir avant de les travailler ou de les monter. Pour assurer la sécurité et le confort de vos chevaux, les sols sont recouverts de tapis en caoutchouc antidérapants de haute qualité.',
    imgSrc: '/ins(24).jfif'
  },
  {
    id: 'feature1',
    title: 'Localisation',
    text: `Situé dans un cadre enchanteur, à seulement 5 minutes de Lisieux et 15 km de Pont l'Évêque, notre haras privé offre des installations de premier ordre pour le bien-être, l'entraînement et la passion des chevaux. Découvrez les caractéristiques de nos infrastructures, conçues pour offrir le meilleur confort à tous les chevaux.`,
    imgSrc: '/loc.png'
  },
  // Ajoutez d'autres sections si nécessaire
];

const InstallationsPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="installations-page">
      <div className="header-image">
        <img src="/ins (12).jfif" alt="Installations" />
        <div className="header-text">
          <h1>Installations</h1>
        </div>
      </div>
      <div className="description">
        <p>Nos installations sont conçues pour offrir le meilleur environnement possible aux chevaux et à leurs propriétaires. Découvrez nos installations modernes et sécurisées.</p>
      </div>
      {features.map((feature, index) => (
        <div id={feature.id} className={`section ${index % 2 === 0 ? 'text-left' : 'text-right'}`} key={index}>
          <div className="text">
            <h2>{feature.title}</h2>
            <p>{feature.text}</p>

          </div>
          <div className="image">
            <img src={feature.imgSrc} alt={feature.title} />
          </div>
        </div>
      ))}
      {sections.map((section, index) => (
        <div id={section.id} className={`section ${index % 2 === 0 ? 'text-left' : 'text-right'}`} key={index}>
          <div className="text">
            <h2>{section.title}</h2>
            <p>{section.text}</p>
          </div>
          <div className="image">
            <img src={section.imgSrc} alt={section.title} />
          </div>
        </div>
      ))}
    </div>
  );
}

export default InstallationsPage;
