// src/components/Section3.js
import React from 'react';
import './Section3.css';

const Section3 = () => {
  return (
    <div className="section" id="team">
      <div className="container">
        <div className="team-header">Notre équipe</div>
        <div className="team-container">
          <div className="team-member">
            <img src="/alia2.jpg" alt="emile" />
            <p>Emile Menasria</p>
          </div>
          <div className="team-member">
          <img src="/alia3.jpg" alt="anne-claire" />
            <p>Anne-Claire Henon</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Section3;
