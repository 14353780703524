import React, { useEffect } from 'react';
import './EcurieActivePage.css'; // Assurez-vous de créer ce fichier CSS

const features = [
  {
    id: 'feature1',
    title: 'Le concept de l’écurie active',
    text: `L'écurie active est une innovation dans le monde de l'équitation qui vise à améliorer le bien-être des chevaux en leur offrant un environnement plus naturel et dynamique. Contrairement aux écuries traditionnelles où les chevaux sont souvent confinés dans des boxes individuels, une écurie active permet aux chevaux de se déplacer librement dans un espace plus vaste, stimulant ainsi leur santé physique et mentale grâce à une meilleure socialisation et mobilité.`,
    imgSrc: '/active115.jpg'
  },
  {
    id: 'feature2',
    title: 'Distributeur automatique de floconnés',
    text: `Le distributeur automatique de floconnés contribue à personnaliser et adapter la ration journalière de chaque cheval. Elle peut ainsi être fractionnée de manière à la répartir sur une journée complète. Par ailleurs, le cheval se nourrissant est isolé et protégé des autres chevaux par l’intermédiaire d’une baguette électrique mobile disposée à l’entrée de la station. De cette façon, il est en mesure de manger en toute tranquillité.
    C’est l’outil idéal pour permettre de distribuer individuellement les rations aux chevaux en toute simplicité, tout en préservant un mode de vie naturel en groupe.`,
    imgSrc: '/dac.jpg'
  },
  {
    id: 'feature3',
    title: 'Dortoir paillé de 200m2 ',
    text: `Un environnement sécurisé et confortable, propice à leur épanouissement physique et mental, la zone de couchage est un espace essentiel pour le bien-être des équidés évoluant en plein air. Les chevaux, habitués à la vie en extérieur, tirent un grand avantage des abris lors de conditions météorologiques défavorables. En effet, bien que robustes et adaptés à diverses conditions climatiques, les chevaux l’apprécient tout particulièrement lors de journées très ensoleillées, de pluies battantes ou vents violents. En se réfugiant dans un abri, les chevaux peuvent se préserver du chaud comme du froid et de l’humidité excessives, ce qui contribue à maintenir leur santé et leur confort optimal.
    De plus, ces structures offrent un endroit sûr où les équidés peuvent se reposer, se détendre et se protéger des éléments, favorisant ainsi leur bien-être général.`,
    imgSrc: '/dortoir.jpg'
  },
  {
    id: 'feature4',
    title: 'Râtelier XL de 12 places avec auvent',
    text: `Le MUST-HAVE pour les chevaux en écurie active, le râtelier XL avec un auvent est l’outil indispensable, afin de nourrir les chevaux en toute sécurité. En effet, il offre un confort et une praticité inégalée pour la distribution du fourrage aux chevaux. Grâce à sa structure très solide et sa toiture extra-large, les chevaux peuvent fourrager à l’abri de la pluie ou du soleil.`,
    imgSrc: '/s (1).jpg'
  },
  {
    id: 'feature5',
    title: 'Bar à Minéraux',
    text: `Un bar à minéraux dans une écurie active est essentiel pour assurer la santé et le bien-être des chevaux. Ce dispositif offre un accès libre et continu à divers minéraux et vitamines nécessaires pour compenser les carences alimentaires et soutenir les fonctions corporelles vitales. Il est conçu pour encourager les chevaux à s'auto-sélectionner les minéraux dont ils ont besoin, favorisant ainsi une nutrition équilibrée et une meilleure condition physique. En intégrant un bar à minéraux, l'écurie active simule un environnement naturel où les chevaux peuvent réguler leurs apports nutritionnels de manière autonome.`,
    imgSrc: '/s (4).jpg'
  },
  {
    id: 'feature6',
    title: 'Abreuvoir à niveau constant',
    text: `L’approvisionnement en eau potable propre est essentiel dans un hébergement en groupe. L’eau, ressource vitale pour l’équidé, est non seulement indispensable à la digestion et au métabolisme du cheval, mais aussi au maintien d’une bonne thermorégulation.
    Pour reproduire les comportements naturels des chevaux lorsqu’ils boivent, les abreuvoirs doivent notamment proposer au cheval de boire avec la tête aussi basse que possible, privilégier une consommation d’eau directement à la surface de l’abreuvoir.
    Que ce soit en hiver avec le risque de gel et la consommation d’eau augmentée par l’alimentation à base de fourrage sec, ou en été avec la chaleur, sécuriser la disponibilité d’eau potable par une automatisation adaptée est indispensable dans une écurie active.`,
    imgSrc: '/active3333.jpg'
  },
];

const sections = [
  {
    id: 'Zone de roulade',
    title: 'Zone de roulade',
    text: 'La zone de roulade dans une écurie active est un espace spécialement conçu pour permettre aux chevaux de se rouler librement. Cette activité est essentielle pour leur bien-être, car elle aide à soulager les démangeaisons, à étirer leurs muscles, et à détendre leur dos. La zone de roulade est équipée d\'un sol sablé, offrant une surface douce et confortable qui réduit les risques de blessures. Elle constitue également un espace de jeu et de plaisir, favorisant un comportement naturel et équilibré chez les chevaux.',
    imgSrc: '/roulade.jfif'
  },
  {
    id: 'Sas',
    title: 'Sas d’entrée et de sortie',
    text: 'Le sas d’entrée et de sortie dans une écurie active est un dispositif de gestion des mouvements des chevaux entre l’entré et la sortie de l’écurie active. Ce système sécurisé réduit le stress et les conflits entre les chevaux en limitant les interactions brusques. Le sas assure également un contrôle efficace de l’accès, facilitant l’entrée et sortie des chevaux et maintenant une organisation fluide des déplacements au sein de l’écurie active.',
    imgSrc: '/g2.jpg'
  },
  {
    id: 'Butte d’Observation',
    title: 'Butte d’Observation',
    text: 'La butte d’observation dans une écurie active est un monticule surélevé conçu pour permettre aux chevaux de surveiller leur environnement. Elle offre aux chevaux une vue panoramique de leur territoire, ce qui satisfait leur instinct naturel de vigilance. En montant sur la butte, les chevaux peuvent se sentir en sécurité tout en observant leurs compagnons et les alentours. Cette structure enrichit leur cadre de vie, encourageant les comportements naturels et contribuant à leur bien-être mental et physique.',
    imgSrc: '/butte.jfif'
  },
];

const EcurieActivePage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="ecurie-active-page">
      <div className="header-image">
        <img src="/g1.jfif" alt="Écurie Active" />
        <div className="header-text">
          <h1>Écurie Active</h1>
        </div>
      </div>
      <div className="description">
        <p>Une écurie active est un concept moderne d'hébergement des chevaux qui met l'accent sur le bien-être et la santé des chevaux en les maintenant en groupe dans un environnement naturel et stimulant. Ce type d'écurie permet aux chevaux de vivre en liberté, de se déplacer librement et d'interagir socialement, ce qui favorise leur équilibre physique et mental.</p>
      </div>
      {features.map((feature, index) => (
        <div id={feature.id} className={`section ${index % 2 === 0 ? 'text-left' : 'text-right'}`} key={index}>
          <div className="text">
            <h2>{feature.title}</h2>
            <p>{feature.text}</p>
          </div>
          <div className="image">
            <img src={feature.imgSrc} alt={feature.title} />
          </div>
        </div>
      ))}
      {sections.map((section, index) => (
        <div id={section.id} className={`section ${index % 2 === 0 ? 'text-left' : 'text-right'}`} key={index}>
          <div className="text">
            <h2>{section.title}</h2>
            <p>{section.text}</p>
          </div>
          <div className="image">
            <img src={section.imgSrc} alt={section.title} />
          </div>
        </div>
      ))}
      <div className="section text-left" id="pricing">
        <div className="text">
          <h2><span className="dark-blue">Tarif</span><span className="light-blue"> pension écurie active </span></h2>
          <ul>
            <li>Tout le confort d'une vie au pré + : </li>
            <li>Distributeur automatique de floconnés pour une alimentation en continue </li>
            <li>Ratelier foin XL 12 places avec auvent </li>
            <li>Dortoir paillé de 200m² </li>
            <li>Terrain de 2000 m² de sol stabilisé </li>
            <li>Abreuvoir automatique niveau constant </li>
            <li>2 zones de roulade sablées (1 extérieure et 1 couverte)</li>
            <li>Bar à minéraux et vitamines</li>
            <li>Sas d'entrée et de sortie</li>
            <li>Butte d'observation</li>
            <li>Plusieurs prairies en pâturage tournant </li>
            <li>Paddock d'intégration pour faire connaissance avec les nouveaux en toute sécurité</li>
            <li>Surveillance quotidienne</li>
            <li>Accès à l'ensemble des installations</li>
            <li>Une place pour vos affaires dans la sellerie</li>
            <li>Un cours collectif par semaine</li>
            <li><span className="tarifs">Tarifs: 320 € TTC/mois</span></li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default EcurieActivePage;
