import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './Header.css';

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const navigate = useNavigate();

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const navigateToSection = (path, sectionId) => {
    navigate(path);
    setTimeout(() => {
      const element = document.getElementById(sectionId);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }, 300); // Augmenter la durée ici pour s'assurer que la navigation est complète
  };

  return (
    <>
      <div className="top-bar">
        <div className="social-icons">
          <a href="https://www.facebook.com/profile.php?id=100087647890786" target="_blank" rel="noopener noreferrer">
            <i className="fab fa-facebook-f" aria-label="Facebook"></i>
          </a>
          <a href="https://www.tiktok.com/@harasdelaperellina" target="_blank" rel="noopener noreferrer">
            <i className="fab fa-tiktok" aria-label="TikTok"></i>
          </a>
          <a href="https://www.instagram.com/harasdelaperellina/" target="_blank" rel="noopener noreferrer">
            <i className="fab fa-instagram" aria-label="Instagram"></i>
          </a>
        </div>
        <div className="contact-info">
          <span>+33 7 54 23 57 89</span>
        </div>
      </div>
      <header className="header">
        <div className="logo">
          <Link to="/" onClick={() => navigateToSection('/', 'home')}>
            <img src="Haras de la Perellina.jpg" alt="Haras de la Perellina" />
          </Link>
        </div>
        <nav>
          <Link to="/" onClick={() => navigateToSection('/', 'installations')}>Installations</Link>
          <Link to="/section2/ecurie-active" onClick={() => navigateToSection('/section2/ecurie-active', 'ecurie-active-title')}>Écurie Active</Link>
          <Link to="/section2/pension-pre" onClick={() => navigateToSection('/section2/pension-pre', 'pension-pre-title')}>Pension pré</Link>
          <Link to="/" onClick={() => navigateToSection('/', 'about')}>Notre équipe</Link>
          <Link to="/" onClick={() => navigateToSection('/', 'contact')}>Contact</Link>
        </nav>
        <div className="hamburger" onClick={toggleMenu}>
          <i className="fas fa-bars"></i>
        </div>
        <div className={`hamburger-menu ${isMenuOpen ? 'show' : ''}`}>
          <Link to="/" onClick={() => { toggleMenu(); navigateToSection('/', 'home'); }}>Accueil</Link>
          <Link to="/" onClick={() => { toggleMenu(); navigateToSection('/', 'installations'); }}>Installations</Link>
          <Link to="/section2/ecurie-active" onClick={() => { toggleMenu(); navigateToSection('/section2/ecurie-active', 'ecurie-active-title'); }}>Écurie Active</Link>
          <Link to="/section2/pension-pre" onClick={() => { toggleMenu(); navigateToSection('/section2/pension-pre', 'pension-pre-title'); }}>Pension pré</Link>
          <Link to="/" onClick={() => { toggleMenu(); navigateToSection('/', 'about'); }}>Notre équipe</Link>
          <Link to="/" onClick={() => { toggleMenu(); navigateToSection('/', 'contact'); }}>Contact</Link>
        </div>
      </header>
    </>
  );
};

export default Header;
