import React from 'react';
import './Section4.css'; // Create this CSS file based on the style provided in the HTML

const Section4 = () => {
    return (
        <div className="section-3 container-fluid" id="contact">
            <div className="section">
                <img src="tel.png" alt="Icône du Haras de la Perellina" />
                <div className="section-content">
                    <h2>Le Haras de la Perellina</h2>
                    <p>Au cœur du pays d'Auge à seulement 7 minutes du centre-ville de Lisieux, 20 minutes de Deauville et 10 min de Pont l'êvèque.</p>
                    <p>Email : <a href="mailto:emilemenasria@gmail.com">emilemenasria@gmail.com</a></p>
                    <a href="tel:+33754235789" className="contact-button">
                        +33 7 54 23 57 89
                    </a>
                </div>
            </div>
        </div>
    );
}

export default Section4;
