import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import Header from './components/Header';
import Section1 from './components/Section1';
import Section2 from './components/Section2';
import Section3 from './components/Section3';
import Section4 from './components/Section4';
import Section5 from './components/Section5';
import Footer from './components/Footer';
import EcurieActivePage from './components/EcurieActivePage';
import InstallationsPage from './components/InstallationsPage';
import PensionPrePage from './components/PensionPrePage';

const ScrollToSection = ({ sectionId }) => {
  React.useEffect(() => {
    const element = document.getElementById(sectionId);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }, [sectionId]);

  return null;
};

function App() {
  return (
    <Router>
      <div className="App">
        <Header />
        <Routes>
          <Route path="/" element={
            <>
              <section id="home">
                <Section1 />
              </section>
              <section id="installations">
                <Section2 />
              </section>
              <section id="about">
                <Section3 />
              </section>
              <section id="services">
                <Section4 />
              </section>
              <section id="gallerie-photos">
                <Section5 />
              </section>
            </>
          } />
          <Route path="/installations" element={<InstallationsPage />} />
          <Route path="/ecurie-active" element={<EcurieActivePage />} />
          <Route path="/pension-pre" element={<PensionPrePage />} />
          <Route path="/section2/ecurie-active" element={
            <>
              <Section2 />
              <ScrollToSection sectionId="ecurie-active-title" />
            </>
          } />
          <Route path="/section2/pension-pre" element={
            <>
              <Section2 />
              <ScrollToSection sectionId="pension-pre-title" />
            </>
          } />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
