import React from 'react';
import './Footer.css'; // Assurez-vous que ce fichier CSS est bien lié

const Footer = () => {
    return (
        <footer className="footer">
            <div className="footer-container">
                <div className="footer-section">
                    <h3>À propos de nous</h3>
                    <p>Haras De La Perellina, petit haras privé situé aux portes de Lisieux propose une formule écurie active et une formule pension pré.</p>
                </div>
                <div className="footer-section">
                    <h3>Contact</h3>
                    <p>Email : <a href="mailto:emilemenasria@gmail.com">emilemenasria@gmail.com</a></p>
                    <p>Téléphone : <a href="tel:+33754235789">+33 7 54 23 57 89</a></p>
                </div>
                <div className="footer-section">
                    <h3>Suivez-nous</h3>
                    <p><a href="#">Facebook</a></p>
                    <p><a href="#">TikTok</a></p>
                    <p><a href="#">Instagram</a></p>
                </div>
                <div className="footer-section footer-nav">
                    <h3>Navigation</h3>
                    <ul>
                        <li><a href="#ecurie-active">Écurie active</a></li>
                        <li><a href="#pension-pre">Pension pré</a></li>
                        <li><a href="#formules">Formules</a></li>
                        <li><a href="#installation">Installation</a></li>
                        <li><a href="#adresse">Adresse</a></li>
                        <li><a href="#contact">Contact</a></li>
                        <li><a href="#gallerie-photos">Galerie photos</a></li>
                        <li><a href="#notre-equipe">Notre Équipe</a></li>
                    </ul>
                </div>
            </div>
            <div className="footer-bottom">
                &copy; 2024 Le Haras de la Perellina. Tous droits réservés.
            </div>
        </footer>
    );
}

export default Footer;
